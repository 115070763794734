import './DeviceHandler.css';
import { characteristicUuidBolt, radioServiceBolt, serviceUuidSpheroBolt, softSleepBolt, wakeBolt } from '../BoltConstants.js';
import { exponentialBackoff, hexStringToArrayBuffer, time } from '../Util.js';
import { useState, useRef} from 'react';

let device = null;

// Connect
const DeviceHandler = (props) => {
  async function promptConnect() {
  navigator.bluetooth.requestDevice({
    filters: [{ services: [serviceUuidSpheroBolt] }]
  })
    .then(selectedDevice => {
      device = selectedDevice;
      device.addEventListener('gattserverdisconnected', onGattServerDisconnected);
      console.log('Device:', device);
      return device.gatt.connect();
    })
    .then(server => {
      console.log('Connected to GATT server:', server);
      return server.getPrimaryService(serviceUuidSpheroBolt);
    })
    .then(service => {
      console.log('Primary service:', service);
      return service.getCharacteristic(characteristicUuidBolt);
    })
    .then(characteristic => {
      console.log('Characteristic:', characteristic);
      characteristic.startNotifications().then(_ => {
        characteristic.addEventListener('characteristicvaluechanged', onCharacteristicValueChanged);
      });
      writeCharacteristic(device, radioServiceBolt, characteristicUuidBolt, hexStringToArrayBuffer(wakeBolt), false);
      setConnected(true)
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const HandleConnect = () => {
    promptConnect();
  }

  // Reconnect
  async function promptReconnect() {
    if(!device){
      console.log('No device found');
      return;
    }

    if(!device.gatt.connected){
      exponentialBackoff(
        5, // Max Retries
        5, // Delay Between Attemps
        function toTry() {
          time('Trying to reconnect to Bluetooth Device... ');
          device.addEventListener('gattserverdisconnected', onGattServerDisconnected);
          console.log('Device:', device);
          return device.gatt.connect()
          .then(server => {
            console.log('Connected to GATT server:', server);
            return server.getPrimaryService(serviceUuidSpheroBolt);
          })
          .then(service => {
            console.log('Primary service:', service);
            return service.getCharacteristic(characteristicUuidBolt);
          })
          .then(characteristic => {
            console.log('Characteristic:', characteristic);
            characteristic.startNotifications().then(_ => {
              characteristic.addEventListener('characteristicvaluechanged', onCharacteristicValueChanged);
            });
            writeCharacteristic(device, radioServiceBolt, characteristicUuidBolt, hexStringToArrayBuffer(wakeBolt), false);
            setConnected(true)
          })
          .catch(error => {
            console.error('Error:', error);
          });
        },
        function success() {
          console.log('Bluetooth Device successfully reconnected connected');
        },
        function fail() {
          time('Failed to reconnect');
        });
    }else{
      console.log('Device already connected');
    }
  } 

  const HandleReconnect = () => {
    promptReconnect();
  }

  function isAutoreconnect() {
    let checkBox = document.getElementById('autoreconnect');
    return checkBox.checked ? true : false;
  }

  // Disconnect
  async function promptDisconnect() {
    if (!device) {
      setConnected(false)
      return;
    }
    time('Disconnecting from Bluetooth Device...');
    if (device.gatt.connected) {
      await writeCharacteristic(device, radioServiceBolt, characteristicUuidBolt, hexStringToArrayBuffer(softSleepBolt), false);
      setConnected(false)
      device.gatt.disconnect()
      
    } else {
      setConnected(false)
      console.log('Bluetooth Device is already disconnected');
    }
  }

  const HandleDisconnect = () => {
    promptDisconnect();
  }

  /* Convert raw data bytes to hex values just for the sake of showing something.
  In the "real" world, you'd use data.getUint8, data.getUint16 or even
  TextDecoder to process raw data bytes. 
  */
  function onCharacteristicValueChanged(event) {
    let value = event.target.value;
    let a = [];

    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    let byteValue = a.join(' ');
    convertBytesToPacket(byteValue);
  }

  let packetArray = [];  function convertBytesToPacket(byte) {
    const startOfPacket = '0x8d';
    const endOfPacket = '0xd8';
    if(byte === startOfPacket) {
      if(packetArray.length === 0){
        packetArray.push(startOfPacket);
      }else{
        packetArray = []
        packetArray.push(startOfPacket);
      }
    } else if (byte === endOfPacket){
      packetArray.push(endOfPacket);
      console.log(packetArray);
      packetArray = [];
      setPacketCount(incrementPacketCounter());
    } else {
      packetArray.push(byte);
    }
  }

  function onGattServerDisconnected () {
    time('Bluetooth has dropped connection');
    setDisconnectCount(incrementDisconnectCounter());
    setConnected(false)
    if(isAutoreconnect()){
      promptReconnect();
    }
  }

  async function writeCharacteristic(device, serviceUuid, characteristicUuid, value, withoutResponse) {
    let service = await device.gatt.getPrimaryService(serviceUuid);
    let characteristic = await service.getCharacteristic(characteristicUuid);
    if(withoutResponse===true){
        return await characteristic.writeValueWithoutResponse(value);
    }
    else {
        return await characteristic.writeValueWithResponse(value);
    }
  }

  // Bolt
  async function writeToBolt(description, hexPacket) {
    if (!device) {
        console.log('No Bluetooth Device found');
        return;
    }
    console.log(description)
    if (device.gatt.connected) {
        await writeCharacteristic(device, radioServiceBolt, characteristicUuidBolt, hexStringToArrayBuffer(hexPacket), true);
    } else {
        console.log('Bluetooth Device is disconnected');
      }
  }

  const [startTime, setStartTime] = useState(null);
  const [now, setNow] = useState(null);
  const intervalTimeRef = useRef(null);

  const [packetCount, setPacketCount] = useState(0);
  const intialPacketCount = useRef(0);

  const HandleStartAccelerometerStreaming = () => {
    intialPacketCount.current = packetCount;
    writeToBolt('Enabling Streaming for Accelerometer Sensor', '8D 3A 12 01 18 00 00 00 96 00 00 00 E0 00 24 D8');
    setStartTime(Date.now())
    setNow(Date.now())

    clearInterval(intervalTimeRef.current);
    intervalTimeRef.current = setInterval(() => {
      setNow(Date.now());
    }, 10);
  }

  const [expectedPackets, setExpectedPackets] = useState(0);
  const [actualPackets, setActualPackets] = useState(0);
  const HandleMaskAccelerometerStreaming = () => {
    writeToBolt('Masking Steaming for Accelerometer Sensor', '8D 3A 12 01 18 00 00 00 96 00 00 00 00 00 04 D8');
    clearInterval(intervalTimeRef.current);

    let milisecondTime = now - startTime;
    let seconds = milisecondTime / 1000;
    console.log('*** You were data streaming for: ' + seconds + 'seconds ***');

    setExpectedPackets(milisecondTime /150);
    setActualPackets(packetCount - intialPacketCount.current)

    // console.log('*** START OF STREAMING RESULTS ***');
    // console.log('Expected Number: %d ', expectedPackets);
    // console.log('Actual Packets: %d ', actualPackets);
    // console.log('*** END OF STREAMING RESULTS ***');
  }

  let secondsPassed = 0;
  if (startTime != null && now != null) {
    secondsPassed = (now - startTime) / 1000;
  }

  // const handleMoveForward = () => {
  //   writeToBolt('Move Forward...', '8D 38 12 01 16 07 FF 7F 00 00 00 19 D8');
  // }     

  // const handleStop = () => {
  //   writeToBolt('Move Stop...', '8D 38 12 01 16 07 FF 00 00 00 00 19 D8');
  // }

  const [connected, setConnected] = useState(null);
  
  const HandleGreenLeds = () => {
    writeToBolt('Setting green LED color...', '8D 3A 12 01 1A 2F FF 00 FF 00 6B D8');
    writeToBolt('Waking Bolt', wakeBolt)
  }

  const HandleBlueLeds = () => {
    writeToBolt('Setting blue LED color...', '8D 3A 12 01 1A 2F FF 00 00 FF 6B D8');
    writeToBolt('Waking Bolt', wakeBolt)
  }
    
  let disconnectCounter = 0
  const [disconnectCount, setDisconnectCount] = useState(0);
  function incrementDisconnectCounter() {
    disconnectCounter++;
    console.log('Number of disconnects: %d', disconnectCounter);
    return disconnectCounter;
  }

  let packetsSent = 0;
  function incrementPacketCounter() {
    packetsSent++;
    console.log('Number of packets sent out: %d', packetsSent);
    return packetsSent;
  }
  
  return (
    <div>
      <h2>Connection</h2>
      <button className='button' onClick={HandleConnect}>Connect</button>
      <button className='button' onClick={HandleDisconnect}>Disconnect</button>
      <button className='button' onClick={HandleReconnect}>Reconnect</button>

      <h2>Bolt Actions</h2>
      <button className='button' onClick={HandleStartAccelerometerStreaming}>Start Streaming </button>
      <button className='button' onClick={HandleMaskAccelerometerStreaming}>Stop Streaming </button>
      <button className='button' onClick={HandleGreenLeds}>Wake + Green Matrix LEDs</button>
      <button className='button' onClick={HandleBlueLeds}>Wake + Blue Matrix LEDs</button>

      <h2>Utility</h2>
      <label className='container'> Autoreconnect
        <input type="checkbox" id='autoreconnect' onClick={isAutoreconnect}></input>
        <span className='checkmark'></span>
      </label>

      <h2>Streaming Status</h2>
      <h3>Streaming time passed: {secondsPassed.toFixed(3)}</h3>
      <h3>Past test | Expected packets: {expectedPackets}</h3>
      <h3>Past test | Acutal packets: {actualPackets}</h3>
      <h3>Total packets sent: {packetCount} </h3>


      <h2>Connection Status</h2>
      <h3>{connected ? 'You are connected' : 'You are not connected'}</h3>
      <h3>Total number of disconnects: {disconnectCount} </h3>

    </div>
  );
}

export default DeviceHandler;
 