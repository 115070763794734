import DeviceHandler from "./utils/WebBLE/DeviceHandler";

function App() {
  return (
    <div>
      <DeviceHandler/> 
    </div>
  );
}

export default App;
